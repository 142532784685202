import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { User, Menu, X, Edit, Check, AlertTriangle, CreditCard, Zap, Star, Infinity, Crown, Rocket } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
import supabase from '../supabaseConfig';

// Base Button Component
const Button = ({ children, className, onClick, disabled, ...props }) => (
    <button
        className={`px-4 py-2 rounded-full transition-all duration-200 ${className}`}
        onClick={onClick}
        disabled={disabled}
        {...props}
    >
        {children}
    </button>
);

// ProfileModal Component
const ProfileModal = ({ isOpen, onClose, profile, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        avatarUrl: '',
        username: '',
        fullName: '',
        jobTitle: '',
        credits: 0,
        email: '',
    });
    const [error, setError] = useState(null);

    useEffect(() => {
        if (profile) {
            setFormData({
                avatarUrl: profile.avatar_url || '',
                username: profile.username || '',
                fullName: profile.full_name || '',
                jobTitle: profile.job_title || '',
                credits: profile.credits || 0,
                email: profile.email || '',
            });
        }
    }, [profile]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const MAX_AVATAR_SIZE_MB = 5;
        const MAX_FILE_SIZE_BYTES = MAX_AVATAR_SIZE_MB * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE_BYTES) {
            setError(`File is too large. Maximum allowed size is ${MAX_AVATAR_SIZE_MB} MB.`);
            return;
        }

        try {
            setLoading(true);
            const fileExt = file.name.split('.').pop();
            const fileName = `${Date.now()}.${fileExt}`;
            const filePath = `avatars/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('avatars')
                .upload(filePath, file, { upsert: true });

            if (uploadError) throw uploadError;

            const { data: publicUrlData } = supabase.storage
                .from('avatars')
                .getPublicUrl(filePath);

            setFormData(prev => ({
                ...prev,
                avatarUrl: publicUrlData.publicUrl
            }));
            setError(null);
        } catch (error) {
            console.error("Upload error:", error);
            setError('Error uploading image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!profile?.id) {
            setError("No profile ID available");
            return;
        }

        if (!formData.fullName || !formData.jobTitle) {
            setError("Full Name and Job Title are required.");
            return;
        }

        try {
            setLoading(true);

            const { error: updateError } = await supabase
                .from('profiles')
                .update({
                    job_title: formData.jobTitle,
                    avatar_url: formData.avatarUrl,
                    full_name: formData.fullName,
                })
                .eq('id', profile.id);

            if (updateError) throw updateError;

            onUpdate({
                ...profile,
                job_title: formData.jobTitle,
                avatar_url: formData.avatarUrl,
                full_name: formData.fullName,
            });

            onClose();
        } catch (error) {
            console.error("Update error:", error);
            setError(`Error updating profile: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex items-center space-x-4 mb-4 justify-center">
                        <div className="w-24 h-24 rounded-full bg-gray-200 overflow-hidden shadow-md">
                            {formData.avatarUrl ? (
                                <img
                                    src={formData.avatarUrl}
                                    alt="Profile"
                                    className="w-full h-full object-cover"
                                />
                            ) : (
                                <User className="w-full h-full p-4 text-gray-400" />
                            )}
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="hidden"
                            id="avatar-upload"
                        />
                        <label
                            htmlFor="avatar-upload"
                            className="cursor-pointer bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-full hover:from-blue-600 hover:to-purple-700 transition-all"
                        >
                            {loading ? 'Uploading...' : 'Change Avatar'}
                        </label>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Full Name
                            </label>
                            <input
                                type="text"
                                value={formData.fullName}
                                onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
                                placeholder="Enter your full name"
                                className="w-full p-3 border rounded text-gray-700"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Job Title
                            </label>
                            <input
                                type="text"
                                value={formData.jobTitle}
                                onChange={(e) => setFormData(prev => ({ ...prev, jobTitle: e.target.value }))}
                                placeholder="Enter your job title"
                                className="w-full p-3 border rounded text-gray-700"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email
                            </label>
                            <input
                                type="text"
                                value={formData.email}
                                readOnly
                                className="w-full p-3 border rounded bg-gray-100 text-center text-gray-500"
                            />
                        </div>

                        <div className="text-center text-gray-700 bg-gray-50 p-3 rounded">
                            Available Credits: <span className="font-bold text-blue-600">{formData.credits}</span>
                        </div>
                    </div>

                    {error && (
                        <div className="p-3 bg-red-50 border border-red-200 rounded text-red-600 text-sm">
                            {error}
                        </div>
                    )}

                    <div className="flex justify-end space-x-2 mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 border rounded text-gray-500 hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full hover:from-blue-600 hover:to-purple-600 disabled:opacity-50"
                        >
                            {loading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Memoized PricingModal component
const PricingModal = React.memo(({ credits = 0, userId = null, onClose, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState('three_day_trial');
    const [isSpinning, setIsSpinning] = useState(false);

    const plans = useMemo(() => ({
        monthly_pro: {
            sku: 'monthly_pro',
            name: 'Monthly Pro',
            icon: <Rocket className="w-6 h-6 text-purple-500" />,
            period: 'month',
            pitch: "Start landing interviews this week",
            basePrice: 49,
            price: 29,
            discount: 41,
            pricePerApp: 0.39,
            applicationsPerPeriod: 150,
            averageSuccessRate: "25%+",
            responseTime: '24-48h',
            highlight: "Quick Start",
            features: [
                { text: '150 AI Jobs Monthly', detail: 'Fresh opportunities every day' },
                { text: 'AI-Powered Job Matching', detail: '92% matching accuracy' },
                { text: 'Smart Resume Analysis', detail: 'Real-time optimization tips' },
                { text: '30-Day Money-Back Guarantee', detail: 'No questions asked' }
            ]
        },
        quarterly_plus: {
            sku: 'quarterly_plus',
            name: 'Quarterly Plus',
            icon: <Star className="w-6 h-6 text-blue-500" />,
            period: 'quarter',
            pitch: "3 months of premium job hunting",
            basePrice: 89,
            price: 19,
            discount: 34,
            pricePerApp: 0.24,
            applicationsPerMonth: 250,
            monthsIncluded: 3,
            totalApplications: 750,
            averageSuccessRate: "35%+",
            responseTime: '12-24h',
            highlight: "Best Value",
            features: [
                { text: '250 AI Jobs Monthly', detail: '3x more opportunities' },
                { text: 'Priority Applications', detail: 'Get seen first by employers' },
                { text: 'Advanced Job Matching', detail: '95% matching accuracy' },
                { text: 'Quarterly Payment Savings', detail: 'Most flexible option' }
            ]
        },
        lifetime_elite: {
            sku: 'lifetime_elite',
            name: 'Lifetime Elite',
            icon: <Crown className="w-6 h-6 text-amber-500" />,
            period: 'lifetime',
            pitch: "1000 jobs monthly, forever",
            basePrice: 299,
            price: 109,
            discount: 64,
            pricePerApp: "Unlimited",
            applicationsPerMonth: 1000,
            highlight: "Best Long-term",
            features: [
                { text: '1500 Jobs Monthly Forever', detail: 'Maximum monthly applications' },
                { text: 'VIP Application Priority', detail: 'Front of the line access' },
                { text: 'Executive Job Matching', detail: '98% matching accuracy' },
                { text: 'One-Time Payment', detail: 'Lifetime access, no renewals' }
            ]
        },
        three_day_trial: {
            sku: 'three_day_trial',
            name: '3 Day Free Trial',
            icon: <Zap className="w-6 h-6 text-green-500" />,
            period: 'once',
            pitch: "3 days - test the power of robo - 50 applications ",
            basePrice: 30,
            price: 19.99,
            discount: 30,
            pricePerApp: 0.70,
            applicationsPerPeriod: 50,
            averageSuccessRate: "20%+",
            responseTime: '48h',
            highlight: "Quick Boost",
            features: [
                { text: '50 Applications One-Time', detail: 'Use anytime within 3 days' },
                { text: 'AI-Powered Job Matching', detail: '90% matching accuracy' },
                { text: 'Basic Resume Analysis', detail: 'Essential optimization tips' },
                { text: 'Money-Back Guarantee', detail: 'If unused within 3 days' }
            ]
        }
    }), []);

    const handlePurchase = useCallback(async (sku) => {
        setIsSpinning(true);
        setLoading(true);
        setError(null);
        try {
            const checkoutUserId = userId || localStorage.getItem('userId');
            if (!checkoutUserId) throw new Error('Please log in to continue');

            const response = await fetch(
                'https://payment-backend-sage.vercel.app/create-checkout-session',
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ sku, quantity: 1, user_id: checkoutUserId })
                }
            );

            const data = await response.json();
            if (!response.ok) throw new Error(data.detail?.[0]?.msg || 'Checkout failed');
            if (data.checkout_url) {
                onSuccess?.(plans[sku].applicationsPerPeriod);
                window.location.href = data.checkout_url;
            }
            else throw new Error('No checkout URL received');
        } catch (err) {
            setError(err.message);
            console.error('Checkout error:', err);
            setIsSpinning(false);
        } finally {
            setLoading(false);
        }
    }, [userId, onSuccess, plans]);

    const renderAnimatedIcon = (icon) => (
        <div
            className={`transform transition-all duration-700 ${isSpinning ? 'animate-spin-slow' : ''
                }`}
        >
            {icon}
        </div>
    );

    const currentPlan = plans[selectedPlan];

    const getPlanLabel = (planKey) => {
        switch (planKey) {
            case 'monthly_pro':
                return 'Monthly';
            case 'three_day_trial':
                return <span className="font-bold">3-Day Trial</span>;
            case 'quarterly_plus':
                return 'Quarterly';
            case 'lifetime_elite':
                return 'Lifetime';
            default:
                return '';
        }
    };

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <style jsx global>{`
                @keyframes spin-slow {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
                .animate-spin-slow {
                    animation: spin-slow 1s linear infinite;
                }
                .custom-scrollbar::-webkit-scrollbar {
                    width: 6px;
                }
                .custom-scrollbar::-webkit-scrollbar-track {
                    background: #F3F4F6;
                    border-radius: 3px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb {
                    background: #D1D5DB;
                    border-radius: 3px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                    background: #9CA3AF;
                }
            `}</style>

            <div className="min-h-screen px-4 text-center">
                <div
                    className="fixed inset-0 bg-gradient-to-br from-purple-600 via-pink-500 to-red-500 opacity-90"
                    onClick={onClose}
                />

                <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-6">
                    <button
                        onClick={onClose}
                        className="absolute right-6 top-6 text-white hover:text-gray-200 transition-colors"
                    >
                        <X size={24} />
                    </button>

                    {credits <= 0 && (
                        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-8 rounded-r max-w-lg w-full text-center">
                            <p className="text-yellow-700">
                                You have 0 credits remaining. Please purchase credits to continue applying for jobs.
                            </p>
                        </div>
                    )}

                    <div className="text-center mb-8">
                        <h1 className="text-4xl font-bold text-white mb-3">
                            RoboApply Premium
                        </h1>
                        <p className="text-white/90 text-lg">
                            Automated job applications with proven results
                        </p>
                    </div>

                    <div className="flex space-x-2 bg-white/10 backdrop-blur-lg rounded-lg p-1.5 mb-8 shadow-xl">
                        {Object.keys(plans).map((planKey) => (
                            <button
                                key={planKey}
                                onClick={() => setSelectedPlan(planKey)}
                                className={`px-6 py-2.5 rounded-md transition-all text-sm ${selectedPlan === planKey
                                    ? 'bg-white text-purple-600 shadow-lg'
                                    : 'text-white hover:bg-white/10'
                                    }`}
                            >
                                {getPlanLabel(planKey)}
                            </button>
                        ))}
                    </div>

                    <div className="w-full max-w-lg">
                        <div className="relative bg-white rounded-2xl shadow-2xl overflow-hidden">
                            <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-pink-500 via-red-500 via-yellow-500 via-green-500 via-blue-500 to-purple-500 animate-gradient" />
                            <div className="absolute inset-0.5 bg-white rounded-2xl" />

                            <div className="relative p-8 flex flex-col items-center">
                                <div className="flex flex-col items-center mb-8 text-center">
                                    <div className="mb-3">
                                        {renderAnimatedIcon(currentPlan.icon)}
                                    </div>
                                    <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent mb-2">
                                        {currentPlan.name}
                                    </h3>
                                    <span className="bg-purple-100 text-purple-600 px-4 py-1 rounded-full text-sm font-medium border border-purple-200 shadow-sm">
                                        {currentPlan.highlight}
                                    </span>
                                </div>

                                <p className="text-gray-600 mb-8 text-lg text-center">
                                    {currentPlan.pitch}
                                </p>

                                <div className="mb-8 text-center">
                                    <div className="flex items-center justify-center gap-4 mb-2">
                                        <span className="text-3xl text-gray-400 line-through font-medium">
                                            ${currentPlan.basePrice}
                                        </span>
                                        <span className="text-5xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
                                            ${currentPlan.price}
                                        </span>
                                        <div className="inline-flex items-center bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm font-medium border border-green-200 shadow-sm animate-pulse">
                                            Save {currentPlan.discount}%
                                        </div>
                                    </div>
                                    <p className="text-gray-600">
                                        {currentPlan.period === 'lifetime'
                                            ? 'One-time payment'
                                            : currentPlan.period === 'quarter'
                                                ? 'per quarter'
                                                : currentPlan.period === 'once'
                                                    ? 'one-time fee'
                                                    : `per ${currentPlan.period}`}
                                    </p>
                                </div>

                                <ul className="space-y-5 mb-8 w-full max-w-md">
                                    {currentPlan.features.map((feature, index) => (
                                        <li key={index} className="flex flex-col items-center text-center">
                                            <div className="flex items-center justify-center gap-2 mb-1">
                                                <Check className="w-5 h-5 text-green-500" />
                                                <div className="font-medium">{feature.text}</div>
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                {feature.detail}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <button
                                    onClick={() => handlePurchase(currentPlan.sku)}
                                    disabled={loading}
                                    className="w-full max-w-md bg-gradient-to-r from-purple-600 to-pink-600 text-white py-4 rounded-lg hover:from-purple-700 hover:to-pink-700 transition-all transform hover:scale-[1.02] disabled:opacity-50 font-medium shadow-lg"
                                >
                                    {loading ? 'Processing...' : 'Get Started Now →'}
                                </button>

                                {error && (
                                    <div className="mt-6 p-3 bg-red-50 text-red-700 rounded-lg text-center max-w-md w-full">
                                        <div className="flex items-center justify-center text-sm">
                                            <AlertTriangle size={16} className="mr-2" />
                                            {error}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

// Main Header Component
const Header = ({
    remainingCredits,
    profile: initialProfile,
    isMenuOpen,
    setIsMenuOpen,
    setShowPricingScreen,
    navigate: parentNavigate,
    onCreditsUpdated
}) => {
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [currentProfile, setCurrentProfile] = useState(initialProfile);
    const [currentCredits, setCurrentCredits] = useState(remainingCredits || 0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (initialProfile && JSON.stringify(currentProfile) !== JSON.stringify(initialProfile)) {
            setCurrentProfile(initialProfile);
        }
    }, [initialProfile]);

    useEffect(() => {
        if (remainingCredits !== undefined) {
            setCurrentCredits(remainingCredits);
            if (remainingCredits <= 0) {
                setShowPricingModal(true);
                setShowPricingScreen?.(true);
            }
        }
    }, [remainingCredits, setShowPricingScreen]);

    const handleProfileUpdate = async (updatedProfile) => {
        setCurrentProfile(updatedProfile);
    };

    const handleHomeClick = () => {
        if (parentNavigate) {
            parentNavigate('/');
        } else {
            navigate('/');
        }
    };

    const handleBuyCreditsClick = () => {
        setShowPricingModal(true);
        setShowPricingScreen?.(true);
    };

    const handlePricingClose = () => {
        setShowPricingModal(false);
        setShowPricingScreen?.(false);
    };

    const handlePurchaseSuccess = (purchasedCredits) => {
        const newTotal = currentCredits + purchasedCredits;
        setCurrentCredits(newTotal);
        onCreditsUpdated?.(newTotal);
        setShowPricingModal(false);
        setShowPricingScreen?.(false);
    };

    const renderCredits = () => {
        if (isLoading) {
            return (
                <div className="flex items-center space-x-2">
                    <div className="h-8 w-24 bg-blue-100 animate-pulse rounded-full"></div>
                </div>
            );
        }

        return (
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="text-2xl font-semibold text-blue-600 bg-blue-100 px-4 py-2 rounded-full"
            >
                {currentCredits || 0} Credits
            </motion.div>
        );
    };

    return (
        <>
            <header className="bg-white shadow-sm sticky top-0 z-10">
                <nav className="container mx-auto px-4 md:px-6 py-4 flex items-center justify-between">
                    <div
                        onClick={handleHomeClick}
                        className="flex items-center cursor-pointer text-2xl md:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500"
                    >
                        <img src="/upLine2.svg" alt="Logo" className="w-8 h-8 mr-2" />
                        RoboApply
                    </div>

                    <div className="hidden md:flex items-center space-x-6">
                        <div className="flex items-center space-x-4">
                            {renderCredits()}
                            <div
                                onClick={() => setShowProfileModal(true)}
                                className="relative group flex items-center cursor-pointer"
                            >
                                {currentProfile?.avatar_url ? (
                                    <img
                                        src={currentProfile.avatar_url}
                                        alt="Profile"
                                        className="w-10 h-10 rounded-full shadow-md"
                                    />
                                ) : (
                                    <User className="w-10 h-10 text-blue-500 bg-blue-100 rounded-full p-2 shadow-md" />
                                )}
                                <span className="ml-2 text-lg font-semibold text-blue-600">
                                    {currentProfile?.username || "Profile"}
                                </span>
                                <Edit className="w-4 h-4 absolute bottom-0 right-0 text-blue-500 bg-white rounded-full p-0.5 opacity-0 group-hover:opacity-100 transition-opacity" />
                            </div>
                        </div>

                        <Button
                            onClick={handleBuyCreditsClick}
                            className="bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600"
                        >
                            Buy Credits
                        </Button>
                        <Button
                            onClick={handleHomeClick}
                            className="bg-white text-blue-600 border border-blue-600 hover:bg-blue-50"
                        >
                            Back to Home
                        </Button>
                    </div>

                    <Button
                        className="md:hidden text-blue-600"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
                    </Button>
                </nav>

                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            className="md:hidden px-4 pb-4 space-y-4"
                        >
                            <div className="flex items-center justify-between">
                                {renderCredits()}
                                <div
                                    onClick={() => setShowProfileModal(true)}
                                    className="relative group flex items-center cursor-pointer"
                                >
                                    {currentProfile?.avatar_url ? (
                                        <img
                                            src={currentProfile.avatar_url}
                                            alt="Profile"
                                            className="w-10 h-10 rounded-full shadow-md"
                                        />
                                    ) : (
                                        <User className="w-10 h-10 text-blue-500 bg-blue-100 rounded-full p-2 shadow-md" />
                                    )}
                                    <span className="ml-2 text-lg font-semibold text-blue-600">
                                        {currentProfile?.username || "Profile"}
                                    </span>
                                </div>
                            </div>
                            <div className="space-y-2">
                                <Button
                                    onClick={handleBuyCreditsClick}
                                    className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600"
                                >
                                    Buy Credits
                                </Button>
                                <Button
                                    onClick={handleHomeClick}
                                    className="w-full bg-white text-blue-600 border border-blue-600 hover:bg-blue-50"
                                >
                                    Back to Home
                                </Button>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </header>

            {showPricingModal && (
                <PricingModal
                    credits={currentCredits}
                    userId={currentProfile?.id}
                    onClose={handlePricingClose}
                    onSuccess={handlePurchaseSuccess}
                />
            )}

            <ProfileModal
                isOpen={showProfileModal}
                onClose={() => setShowProfileModal(false)}
                profile={currentProfile}
                onUpdate={handleProfileUpdate}
                key={currentProfile?.id}
            />
        </>
    );
};

Header.displayName = 'Header';
PricingModal.displayName = 'PricingModal';
ProfileModal.displayName = 'ProfileModal';

export default Header;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { createClient } from '@supabase/supabase-js';
import { AlertTriangle, Rocket, ShoppingCart } from "lucide-react";
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';

// Component Imports
import Header from '../components/Header';
import ApplicationForm from '../components/ApplicationForm';
import DashboardStats from '../components/DashboardStats';
import ApplicationsGrid from '../components/ApplicationsGrid';
import CreditTrackingMonitor from '../components/CreditTrackingMonitor';
import { FirstTimeCampaignModal } from '../components/FirstTimeFlow';
import { ToastContainer, useToast } from '../components/Toast';
import { CelebrationModal, ProcessingModal } from '../components/Modals';
import SideNavigation from './SideNavigation';

// Campaign Success Modal Component
const CampaignSuccessModal = ({ isOpen, onClose, jobType, numJobs, location, hasCredits }) => {
    useEffect(() => {
        if (isOpen && hasCredits) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    }, [isOpen, hasCredits]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="bg-white rounded-xl p-6 max-w-md w-full mx-4 relative"
            >
                <div className="text-center">
                    {hasCredits ? (
                        <>
                            <motion.div
                                initial={{ y: 20 }}
                                animate={{ y: -10 }}
                                transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    duration: 1
                                }}
                                className="inline-block mb-4"
                            >
                                <Rocket className="h-12 w-12 text-blue-500" />
                            </motion.div>

                            <h2 className="text-2xl font-bold text-gray-800 mb-4">
                                Campaign Launched! 🚀
                            </h2>

                            <div className="space-y-3 mb-6">
                                <p className="text-gray-600">
                                    We've started your campaign for:
                                </p>
                                <div className="bg-blue-50 rounded-lg p-4">
                                    <p className="font-semibold text-blue-900">{numJobs} {jobType} positions</p>
                                    <p className="text-blue-700">{location}</p>
                                </div>
                                <p className="text-gray-600">
                                    You can close this window - we'll email you updates as applications are submitted!
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <motion.div
                                initial={{ y: 20 }}
                                animate={{ y: -10 }}
                                transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    duration: 1
                                }}
                                className="inline-block mb-4"
                            >
                                <ShoppingCart className="h-12 w-12 text-red-500" />
                            </motion.div>

                            <h2 className="text-2xl font-bold text-gray-800 mb-4">
                                Insufficient Credits
                            </h2>

                            <p className="text-gray-600 mb-6">
                                You do not have enough credits to launch this campaign. Please purchase more credits to continue.
                            </p>
                        </>
                    )}

                    <button
                        onClick={onClose}
                        className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-2 rounded-lg font-medium hover:from-blue-600 hover:to-blue-700 transition-all"
                    >
                        Got it!
                    </button>
                </div>
            </motion.div>
        </div>
    );
};

// Initialize Supabase
const supabase = createClient(
    'https://auth.roboapply.ai',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN1a21idnJwY3d6bm1ud3Rlb3liIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4NzQ3ODYsImV4cCI6MjA0NDQ1MDc4Nn0.xR0tIF0NYNnnXa-N2-WpX0qgecGiwywdGwIVg3MgEyg'
);
const ApplyPage = ({ initialCredits, onCreditsUpdated }) => {
    const navigate = useNavigate();
    const monitorRef = useRef(null);
    const { toasts, addToast, removeToast } = useToast();

    // User and Authentication State
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [profile, setProfile] = useState(null);

    // Campaign State
    const [lastCampaign, setLastCampaign] = useState(() => {
        const saved = localStorage.getItem('last_campaign');
        return saved ? JSON.parse(saved) : null;
    });

    // UI State
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showCelebration, setShowCelebration] = useState(false);
    const [showFirstTimeCampaign, setShowFirstTimeCampaign] = useState(false);
    const [showPricingScreen, setShowPricingScreen] = useState(false);
    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successDetails, setSuccessDetails] = useState(null);
    const [currentView, setCurrentView] = useState('dashboard');
    const [showPricingModal, setShowPricingModal] = useState(false);
    // Application Data State
    const [applications, setApplications] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [dataError, setDataError] = useState(null);
    const [successfulApplications, setSuccessfulApplications] = useState(() => {
        return parseInt(localStorage.getItem('total_successful_applications')) || 0;
    });
    const [remainingCredits, setRemainingCredits] = useState(initialCredits);
    const [totalJobsApplied, setTotalJobsApplied] = useState(0);
    const [activelyApplying, setActivelyApplying] = useState(false);



    const [applicationProgress, setApplicationProgress] = useState(() => {
        const saved = localStorage.getItem('application_progress');
        return saved ? JSON.parse(saved) : {
            current: 0,
            total: 0,
            successful: 0
        };
    });

    const [dashboardData, setDashboardData] = useState({
        dataLoading: true,
        dataError: null,
        applicationData: [],
        totalJobsApplied: 0,
        applicationProgress: {
            current: 0,
            total: 0,
            successful: 0,
            failed: 0
        }
    });

    // Toast Helper Function
    const showToast = (messageData, type = 'info', persistent = false) => {
        const message = typeof messageData === 'string'
            ? { description: messageData }
            : messageData;

        return addToast({
            message,
            type,
            persistent,
            duration: 5000
        });
    };
    // Handlers
    const handleSubmit = async (e, formData) => {
        if (e) e.preventDefault();

        try {
            setLoading(true);
            setError(null);
            setActivelyApplying(true);

            const response = await axios.post(
                'https://68d4-45-8-19-36.ngrok-free.app/scrape-and-apply',
                formData
            );

            const { successful_applications, remaining_credits } = response.data;

            // Update application stats and local storage
            const existingTotal = parseInt(localStorage.getItem('total_successful_applications')) || 0;
            const newTotal = existingTotal + successful_applications;
            localStorage.setItem('total_successful_applications', newTotal.toString());

            setSuccessfulApplications(newTotal);
            setRemainingCredits(remaining_credits);
            onCreditsUpdated(remaining_credits);

            // Show the success modal
            setSuccessDetails({
                jobType: formData.job_type,
                numJobs: formData.num_jobs,
                location: formData.location
            });
            setShowSuccessModal(true);

        } catch (error) {
            console.error('Application error:', error);
            showToast({
                title: 'Error',
                description: error.response?.data?.error || 'An error occurred during the application process'
            }, 'error');
            setError(error.response?.data?.error || 'Application process failed');
        } finally {
            setLoading(false);
            setActivelyApplying(false);
        }
    };


    const handleCampaignSubmit = (formData) => {
        const campaign = {
            ...formData,
            timestamp: new Date().toISOString()
        };

        localStorage.setItem('last_campaign', JSON.stringify(campaign));
        setLastCampaign(campaign);

        handleSubmit(null, formData);
    };

    const handleLogout = async () => {
        try {
            await supabase.auth.signOut();
            navigate('/login');
        } catch (error) {
            showToast({
                title: 'Error',
                description: 'Failed to log out. Please try again.'
            }, 'error');
        }
    };
    // Data Fetching Functions
    const fetchDashboardData = async () => {
        if (!user?.id) return;

        try {
            setDashboardData(prev => ({ ...prev, dataLoading: true, dataError: null }));

            const { data: applications, error } = await supabase
                .from('applications')
                .select('*')
                .eq('user_id', user.id)
                .eq('status', 'Successful')
                .order('time_applied', { ascending: true });

            if (error) throw error;

            const successful = applications.filter(app => app.status === 'Successful').length;
            const failed = applications.filter(app => app.status === 'Failed').length;

            const processedData = applications.map(app => ({
                ...app,
                job_type: app.job_type || 'Other',
                formatted_date: format(parseISO(app.time_applied), 'MMM d, yyyy')
            }));

            setDashboardData({
                dataLoading: false,
                dataError: null,
                applicationData: processedData,
                totalJobsApplied: applications.length,
                applicationProgress: {
                    current: successful + failed,
                    total: successful,
                    successful,
                    failed
                }
            });

        } catch (error) {
            console.error('Error fetching dashboard data:', error);
            setDashboardData(prev => ({
                ...prev,
                dataLoading: false,
                dataError: 'Failed to load application data'
            }));
            showToast({
                title: 'Error',
                description: 'Failed to load dashboard data'
            }, 'error');
        }
    };

    const fetchUserData = async (userId) => {
        try {
            const { data: profileData, error: profileError } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', userId)
                .single();

            if (profileError) throw profileError;

            setProfile(profileData);
            setRemainingCredits(profileData.credits || initialCredits);

            await fetchApplicationData(userId);

        } catch (error) {
            showToast({
                title: 'Error',
                description: 'Failed to load user data. Please try again later.'
            }, 'error');
            setError('Failed to load user data.');
        }
    };

    const fetchApplicationData = async (userId) => {
        setDataLoading(true);
        setDataError(null);
        try {
            const { data: latestApplications, error } = await supabase
                .from('applications')
                .select('*')
                .eq('user_id', userId)
                .eq('status', 'Successful')
                .order('time_applied', { ascending: false });

            if (error) throw error;

            const processedApplications = latestApplications.map(app => ({
                ...app,
                status_class: getStatusClass(app.status),
                formatted_date: format(parseISO(app.time_applied), 'PP p')
            }));

            setApplications(processedApplications);
            setTotalJobsApplied(latestApplications.length);
            setSuccessfulApplications(latestApplications.length);

        } catch (error) {
            showToast({
                title: 'Error',
                description: 'Failed to load application data.'
            }, 'error');
            setDataError('Failed to load application data.');
        } finally {
            setDataLoading(false);
        }
    };

    // Helper Functions
    const getStatusClass = (status) => {
        switch (status) {
            case 'Successful':
                return 'bg-green-100 text-green-800';
            case 'Failed':
                return 'bg-red-100 text-red-800';
            case 'Pending':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };
    // Effects
    useEffect(() => {
        fetchDashboardData();

        const subscription = supabase
            .channel('applications_changes')
            .on('postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'applications',
                    filter: `user_id=eq.${user?.id}`
                },
                () => {
                    fetchDashboardData();
                }
            )
            .subscribe();

        return () => subscription.unsubscribe();
    }, [user?.id]);

    useEffect(() => {
        const storedSession = JSON.parse(
            localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`)
        );

        if (storedSession?.user) {
            setUser(storedSession.user);
            setIsAuthenticated(true);
            fetchUserData(storedSession.user.id);

            const isFirstTime = !localStorage.getItem('hasVisitedApplyPage');
            if (isFirstTime) {
                setShowFirstTimeCampaign(true);
            }

            const savedProgress = localStorage.getItem('application_progress');
            if (savedProgress) {
                const progress = JSON.parse(savedProgress);
                setApplicationProgress(progress);
                if (progress.current < progress.total) {
                    showToast({
                        title: 'Previous Campaign Found',
                        description: `There was an incomplete campaign (${progress.current}/${progress.total}). Start a new campaign when ready.`
                    }, 'info');
                }
            }
        } else {
            setIsAuthenticated(false);
            navigate('/login');
        }
    }, [navigate]);

    // Render
    return (
        <div className="min-h-screen bg-gradient-to-r from-green-50 to-blue-50">
            <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
        
        /* Define font variables */
        :root {
          --font-poppins: 'Poppins', sans-serif;
        }

        /* Apply font globally */
        html {
          font-family: var(--font-poppins);
        }

        /* Optional: Customize specific text elements */
        h1, h2, h3 {
          font-family: var(--font-poppins);
          font-weight: 700;
        }

        p, span, div {
          font-family: var(--font-poppins);
          font-weight: 400;
        }

        button {
          font-family: var(--font-poppins);
          font-weight: 600;
        }
      `}</style>
            <Header
                remainingCredits={remainingCredits}
                profile={profile}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                setShowPricingScreen={setShowPricingScreen}
                navigate={navigate}
                onCreditsUpdated={(newCredits) => {
                    setRemainingCredits(newCredits);
                    onCreditsUpdated?.(newCredits);
                }}
            />

            <main className="container mx-auto px-6 py-12">
                {lastCampaign && (
                    <div className="mb-8 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
                        <h3 className="text-lg font-semibold text-gray-800 mb-2">
                            Last Campaign
                        </h3>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            <div>
                                <span className="text-gray-600">Job Type:</span>
                                <p className="font-medium">{lastCampaign.job_type}</p>
                            </div>
                            <div>
                                <span className="text-gray-600">Location:</span>
                                <p className="font-medium">{lastCampaign.location}</p>
                            </div>
                            <div>
                                <span className="text-gray-600">Applications:</span>
                                <p className="font-medium">{lastCampaign.num_jobs}</p>
                            </div>
                            <div>
                                <button
                                    onClick={() => handleCampaignSubmit(lastCampaign)}
                                    className="text-blue-500 hover:text-blue-600 font-medium"
                                >
                                    Run Again
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <ApplicationForm
                        handleSubmit={handleSubmit}
                        loading={loading}
                        error={error}
                        activelyApplying={activelyApplying}
                        userId={user?.id}
                        most_recent_resume_link={profile?.most_recent_resume_url}
                        userEmail={user?.email}
                        diceEmail={profile?.dice_specific_email || user?.email}
                        supabase={supabase}
                        setShowPricingScreen={setShowPricingScreen}
                        setShowPricingModal={setShowPricingModal}
                    />
                    <DashboardStats
                        activelyApplying={activelyApplying}
                        applicationProgress={dashboardData.applicationProgress}
                        totalJobsApplied={dashboardData.totalJobsApplied}
                        dataLoading={dashboardData.dataLoading}
                        dataError={dashboardData.dataError}
                        applicationData={dashboardData.applicationData}
                        userId={user?.id}
                    />
                </div>

                <ApplicationsGrid
                    applications={applications}
                />

                <CreditTrackingMonitor
                    ref={monitorRef}
                    initialCredits={remainingCredits}
                    onInvalidCredentials={() => {
                        setIsAuthenticated(false);
                        navigate('/login');
                    }}
                    showToast={showToast}
                    onCreditsUpdated={(newCredits) => {
                        setRemainingCredits(newCredits);
                        onCreditsUpdated(newCredits);
                    }}
                    setShowPricingScreen={setShowPricingScreen}
                    isMonitoring={activelyApplying}
                />
            </main>

            {/* Modals */}
            <CampaignSuccessModal
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
                jobType={successDetails?.jobType}
                numJobs={successDetails?.numJobs}
                location={successDetails?.location}
                hasCredits={remainingCredits > 0}  // Assuming `remainingCredits` is defined in your component state or props
            />

            <FirstTimeCampaignModal
                isOpen={showFirstTimeCampaign}
                onClose={() => {
                    setShowFirstTimeCampaign(false);
                    localStorage.setItem('hasVisitedApplyPage', 'true');
                }}
                onSubmit={handleCampaignSubmit}
                loading={loading}
                userEmail={profile?.dice_specific_email || user?.email}
                dicePassword={profile?.dice_password}
            />

            <CelebrationModal
                isOpen={showCelebration}
                onClose={() => setShowCelebration(false)}
                successfulApplications={successfulApplications}
                remainingCredits={remainingCredits}
            />

            <ProcessingModal
                isOpen={showRefreshModal}
                onClose={() => setShowRefreshModal(false)}
            />

            {/* Progress Indicator */}
            {activelyApplying && (
                <div className="fixed bottom-0 left-0 right-0 bg-blue-500 text-white py-2 px-4 z-50">
                    <div className="container mx-auto flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <motion.div
                                animate={{ rotate: 360 }}
                                transition={{
                                    duration: 1.5,
                                    repeat: Infinity,
                                    ease: "linear"
                                }}
                                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                            />
                            <span className="font-medium">
                                Progress: {applicationProgress.current} / {applicationProgress.total}
                            </span>
                            <span className="font-medium">
                                Successful: {applicationProgress.successful}
                            </span>
                        </div>
                        <div className="w-1/3 bg-blue-400 rounded-full h-2">
                            <div
                                className="bg-white rounded-full h-2 transition-all duration-500"
                                style={{
                                    width: `${(applicationProgress.current / applicationProgress.total) * 100}%`
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Toast Container */}
            <ToastContainer
                toasts={toasts}
                removeToast={removeToast}
            />
        </div>
    );
};

export default ApplyPage;
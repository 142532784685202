import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";  // Added Navigate here
import { inject } from "@vercel/analytics";  // Import inject for advanced config
import JobApplyAILandingPage from "./components/JobApplyAILandingPage";
import ApplyPage from "./components/ApplyPage";
import SuccessPage from "./components/SuccessPage";
import CancelPage from "./components/CancelPage";
import posthog from "./posthogConfig";
import EnhancedResumeTool from "./components/ResumeEnhancement";
import "./index.css";
import WorkFlow from "./components/Workflow";
import JobCampaignDAG from "./components/DAG";
import JobSearchPortal from "./components/JobSearchPortal";
// Advanced Vercel Analytics configuration
inject({
  mode: process.env.NODE_ENV === "development" ? "development" : "production",
  debug: process.env.NODE_ENV === "development",
  beforeSend: (event) => {
    // Exclude any events with '/private' in the URL
    if (event.url.includes("/private")) {
      return null;
    }
    return event;
  }
});

function App() {
  useEffect(() => {
    // Track page views or other custom events on mount
    posthog.capture('app_loaded');

    // Add auth state cleanup logic
    const cleanupAuth = () => {
      try {
        const authToken = localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
        const userId = localStorage.getItem('userId');

        // If there's a mismatch between auth token and user ID, clear everything
        if ((!authToken && userId) || (authToken && !userId)) {
          localStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        console.error('Auth cleanup error:', error);
        localStorage.clear();
        window.location.reload();
      }
    };

    // Run cleanup on mount
    cleanupAuth();

    // Add storage event listener
    const handleStorageChange = () => {
      cleanupAuth();
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<JobApplyAILandingPage />} />
        <Route path="/apply" element={<ApplyPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/enhance" element={<EnhancedResumeTool />} />
        <Route path="/roboflow" element={<JobCampaignDAG />} />
        <Route path="/dag" element={<JobCampaignDAG />} />
        <Route path="/jobs" element={<JobSearchPortal />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { User, Mail, Lock, Loader, ArrowLeft, Shield, ExternalLink, ChevronDown, Gift, Upload, CheckCircle } from 'lucide-react';
import useNavigate from 'react-router-dom';

import ReactConfetti from 'react-confetti';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import supabase from '../supabaseConfig'; // Adjust path as needed

const MAX_AVATAR_SIZE_MB = 5;
const JOB_TITLES = [
    'Software Engineer',
    'Senior Software Engineer',
    'Full Stack Developer',
    'Frontend Developer',
    'Backend Developer',
    'DevOps Engineer',
    'Data Scientist',
    'Data Analyst',
    'Machine Learning Engineer',
    'Product Manager',
    'Project Manager',
    'UI/UX Designer',
    'Quality Assurance Engineer',
    'Business Analyst',
    'Systems Analyst',
    'Cloud Engineer',
    'Network Engineer',
    'Security Engineer',
    'Technical Writer',
    'Web Developer',
    'Mobile Developer',
    'Game Developer',
    'Data Engineer',
    'DevSecOps Engineer',
    'Research Scientist',
    'Blockchain Developer',
    'AI Engineer',
    'Embedded Systems Engineer',
    'Site Reliability Engineer',
    'Game Designer',
    'IT Support Specialist',
    'Chief Technology Officer (CTO)',
    'Technical Project Manager',
    'Product Designer',
    'Digital Marketing Manager',
    'SEO Specialist',
    'Content Marketing Strategist',
    'Social Media Manager',
    'Email Marketing Specialist',
    'PPC Specialist',
    'Growth Hacker',
    'Brand Manager',
    'Marketing Analyst',
    'Product Marketing Manager',
    'Marketing Coordinator',
    'Affiliate Marketing Manager',
    'Public Relations Specialist',
    'Event Marketing Manager',
    'Influencer Marketing Manager',
    'Creative Director',
    'Art Director',
    'Graphic Designer',
    'Copywriter',
    'Content Strategist',
    'Web Analytics Specialist',
    'User Experience Researcher',
    'Customer Experience Manager',
    'Sales Manager',
    'Account Executive',
    'Sales Development Representative',
    'Business Development Manager',
    'Sales Operations Manager',
    'Inside Sales Representative',
    'Outside Sales Representative',
    'Territory Sales Manager',
    'Field Sales Representative',
    'Regional Sales Manager',
    'Sales Engineer',
    'Key Account Manager',
    'Customer Success Manager',
    'Lead Generation Specialist',
    'Proposal Manager',
    'Channel Sales Manager',
    'Sales Trainer',
    'Revenue Operations Manager',
    'Sales Enablement Manager',
    'Director of Sales',
    'Chief Sales Officer (CSO)',
    'Chief Marketing Officer (CMO)',
    'Head of Growth',
    'Market Research Analyst',
    'Market Intelligence Analyst',
    'Retail Marketing Manager',
    'Product Analyst',
    'Supply Chain Manager',
    'Operations Manager',
    'Logistics Coordinator',
    'Inventory Manager',
    'Vendor Manager',
    'Financial Analyst',
    'Cost Analyst',
    'Investment Analyst',
    'Compliance Manager',
    'Risk Manager',
    'Project Coordinator',
    'Program Manager',
    'Change Manager',
    'Agile Coach',
    'Scrum Master',
    'Operations Analyst',
    'Quality Control Inspector',
    'Facilities Manager',
    'Data Governance Manager',
    'Database Administrator',
    'IT Project Coordinator',
    'Help Desk Technician',
    'Technical Support Engineer',
    'System Administrator',
    'Network Administrator',
    'IT Manager',
    'Chief Information Officer (CIO)',
    'Chief Data Officer (CDO)',
    'Data Privacy Officer',
    'Chief Technology Officer (CTO)',
    'Compliance Analyst',
    'Training and Development Manager',
    'HR Business Partner',
    'Talent Acquisition Specialist',
    'Employee Engagement Manager',
    'Organizational Development Specialist',
    'Diversity and Inclusion Manager',
];

const Input = ({ icon: Icon, ...props }) => (
    <div className="relative">
        <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
            className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            {...props}
        />
    </div>
);

const Select = ({ icon: Icon, ...props }) => (
    <div className="relative">
        <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <select
            className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
            {...props}
        />
    </div>
);

const Button = ({ className, children, ...props }) => (
    <button
        className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 ${className}`}
        {...props}
    >
        {children}
    </button>
);

const SuccessToast = () => (
    <div className="flex items-center space-x-2 bg-white shadow-lg rounded-lg p-4">
        <Gift className="text-purple-500" size={24} />
        <div>
            <h3 className="font-bold text-gray-900">Welcome Aboard! 🎉</h3>
            <p className="text-gray-600">You've received 10 free application credits</p>
        </div>
    </div>
);

const LoadingMessage = ({ message }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex items-center justify-center space-x-3 py-4"
    >
        <Loader className="animate-spin text-blue-500" size={20} />
        <span className="text-gray-600 font-medium">{message}</span>
    </motion.div>
);

const ExistingDiceAccountForm = ({ userEmail, onSubmit, onBack, loading, loadingStep }) => {
    const [password, setPassword] = useState('');
    const [validating, setValidating] = useState(false);
    const [useAlternateEmail, setUseAlternateEmail] = useState(false);
    const [alternateEmail, setAlternateEmail] = useState('');
    const [showEmailOption, setShowEmailOption] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidating(true);
        setError(false);

        try {
            const emailToUse = useAlternateEmail ? alternateEmail : userEmail;
            const response = await axios.post(
                'https://68d4-45-8-19-36.ngrok-free.app/validate-dice-credentials',
                {
                    email: emailToUse,
                    password: password
                }
            );

            if (response.data.status === 'success' &&
                response.data.message === 'Profile credentials valid') {
                // If validation successful, call parent's onSubmit
                await onSubmit({
                    password: password,
                    dice_specific_email: useAlternateEmail ? alternateEmail : null
                });
            } else {
                setError(true);
                setPassword('');
                toast.error('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setError(true);
            setPassword('');
            toast.error('Unable to verify credentials. Please try again.');
        } finally {
            setValidating(false);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="max-w-md mx-auto space-y-6"
        >
            <div className="text-center space-y-4">
                <div className="inline-flex items-center gap-2">
                    <div className="inline-block p-3 rounded-full bg-blue-50 text-blue-500">
                        <User size={24} />
                    </div>
                    <div className="inline-block p-3 rounded-full bg-green-50 text-green-500">
                        <Shield size={24} />
                    </div>
                </div>
                <h2 className="text-xl font-semibold text-gray-800">
                    You already have an existing Dice Account (our application pool platform)
                </h2>
                <p className="text-gray-600">
                    Please enter your Dice.com password to continue with job applications.
                </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4">
                    {/* Email section with alternate email option */}
                    <div className="space-y-2">
                        <div className="relative">
                            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                            <div className="w-full py-2 pl-10 pr-3 bg-gray-50 border border-gray-200 rounded-md text-gray-800">
                                <span className="block truncate">
                                    {useAlternateEmail ? alternateEmail : userEmail}
                                </span>
                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={() => setShowEmailOption(!showEmailOption)}
                            className="text-xs text-gray-500 hover:text-blue-500 flex items-center gap-1 mx-auto transition-colors"
                        >
                            Use different email for Dice.com
                            <ChevronDown
                                size={12}
                                className={`transform transition-transform ${showEmailOption ? 'rotate-180' : ''}`}
                            />
                        </button>

                        {showEmailOption && (
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                className="space-y-2"
                            >
                                <div className="flex items-center gap-2">
                                    <input
                                        type="checkbox"
                                        id="useAlternateEmail"
                                        checked={useAlternateEmail}
                                        onChange={(e) => setUseAlternateEmail(e.target.checked)}
                                        className="rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                    <label htmlFor="useAlternateEmail" className="text-sm text-gray-600">
                                        Use different email for Dice.com login
                                    </label>
                                </div>

                                {useAlternateEmail && (
                                    <input
                                        type="email"
                                        value={alternateEmail}
                                        onChange={(e) => setAlternateEmail(e.target.value)}
                                        placeholder="Enter Dice.com email"
                                        className="w-full py-2 px-3 border border-gray-200 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        required={useAlternateEmail}
                                    />
                                )}
                            </motion.div>
                        )}
                    </div>

                    {/* Password input */}
                    <div className="relative">
                        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(false);
                            }}
                            placeholder="Dice Account Password"
                            required
                            autoFocus
                            disabled={loading || validating}
                            className={`w-full py-2 pl-10 pr-3 border 
                                ${error ? 'border-red-500 bg-red-50' : ''} 
                                ${!error && validating ? 'border-green-500 bg-green-50' : 'border-gray-200'}
                                rounded-md text-gray-800 placeholder-gray-400 
                                focus:outline-none focus:ring-2 
                                ${error ? 'focus:ring-red-500' : 'focus:ring-blue-500'} 
                                focus:border-transparent 
                                disabled:bg-gray-50 disabled:cursor-not-allowed
                                transition-all duration-200`} />
                    </div>

                    {/* Dice.com links */}
                    <div className="flex items-center justify-center gap-4 text-sm text-blue-500">
                        <a
                            href="https://www.dice.com/dashboard/login"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-1 hover:text-blue-600 transition-colors"
                        >
                            Dice Login <ExternalLink size={14} />
                        </a>
                        <span className="text-gray-300">•</span>
                        <a
                            href="https://www.dice.com/dashboard/profiles/resume"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-1 hover:text-blue-600 transition-colors"
                        >
                            View Profile <ExternalLink size={14} />
                        </a>
                    </div>
                </div>

                <AnimatePresence>
                    {(loading || validating) && loadingStep && (
                        <LoadingMessage message={loadingStep} />
                    )}
                </AnimatePresence>

                <div className="space-y-3">
                    <button
                        type="submit"
                        className="w-full py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-md hover:from-blue-600 hover:to-purple-600 disabled:opacity-50 transition-colors"
                        disabled={loading || validating || (useAlternateEmail && !alternateEmail)}
                    >
                        {loading || validating ? (
                            <span className="flex items-center justify-center space-x-2">
                                <Loader className="animate-spin" size={16} />
                                <span>{validating ? 'Validating...' : 'Connecting...'}</span>
                            </span>
                        ) : (
                            'Validate Account'
                        )}
                    </button>

                    <button
                        type="button"
                        onClick={onBack}
                        className="w-full py-2 flex items-center justify-center space-x-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50 transition-colors"
                        disabled={loading || validating}
                    >
                        <ArrowLeft size={16} />
                        <span>Back to Profile Creation</span>
                    </button>
                </div>
            </form>
        </motion.div>
    );
};


const ProfileCreation = ({ user, onComplete }) => {
    const [parent] = useAutoAnimate();
    const [loading, setLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState('');
    const [error, setError] = useState(null);
    const [showConfetti, setShowConfetti] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);

    const [formData, setFormData] = useState({
        username: '',
        jobTitle: '',
        avatar: null,
        avatarUrl: user?.user_metadata?.avatar_url || './einstein.png',
        firstName: '',
        lastName: '',
        resume: null
    });

    useEffect(() => {
        if (user?.user_metadata?.full_name) {
            const [firstName, lastName] = user.user_metadata.full_name.split(' ');
            setFormData(prev => ({
                ...prev,
                firstName: firstName || '',
                lastName: lastName || '',
                username: user.user_metadata.username || ''
            }));
        }
    }, [user]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > MAX_AVATAR_SIZE_MB * 1024 * 1024) {
            toast.error(`File size must be less than ${MAX_AVATAR_SIZE_MB}MB`);
            setError(`File size must be less than ${MAX_AVATAR_SIZE_MB}MB`);
            return;
        }

        try {
            setLoading(true);
            setLoadingStep('Uploading profile picture...');

            const fileExt = file.name.split('.').pop();
            const fileName = `${user.id}_${Date.now()}.${fileExt}`;
            const filePath = `avatars/${fileName}`;

            const { error: uploadError } = await supabase.storage
                .from('avatars')
                .upload(filePath, file, { upsert: true });

            if (uploadError) {
                console.error('Error uploading image:', uploadError);
                throw new Error(`Error uploading image: ${uploadError.message}`);
            }

            const { data: urlData } = supabase.storage
                .from('avatars')
                .getPublicUrl(filePath);

            setFormData(prev => ({
                ...prev,
                avatar: file,
                avatarUrl: urlData.publicUrl
            }));

            toast.success('Profile picture updated successfully!');
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Error uploading image. Please try again.');
            setError(`Error uploading image: ${error.message}`);
        } finally {
            setLoading(false);
            setLoadingStep('');
        }
    };

    const handleResumeUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const allowedTypes = ['.pdf', '.doc', '.docx'];
        const fileExt = `.${file.name.split('.').pop().toLowerCase()}`;

        if (!allowedTypes.includes(fileExt)) {
            toast.error('Please upload a PDF, DOC, or DOCX file');
            setError('Please upload a PDF, DOC, or DOCX file');
            return;
        }

        setFormData(prev => ({ ...prev, resume: file }));
        toast.success('Resume selected successfully!');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!formData.firstName || !formData.lastName || !formData.jobTitle || !formData.resume || !formData.username) {
            toast.error('Please fill in all required fields and upload a resume.');
            setError('Please fill in all required fields and upload a resume.');
            return;
        }

        try {
            setLoading(true);
            setLoadingStep('Creating account...');
            await new Promise(resolve => setTimeout(resolve, 1000));

            const { error: profileError } = await supabase
                .from('profiles')
                .upsert({
                    id: user.id,
                    username: formData.username,

                });

            if (profileError) throw profileError;

            setLoadingStep('Setting up your profile...');
            await new Promise(resolve => setTimeout(resolve, 1000));

            const registrationData = new FormData();
            registrationData.append('first_name', formData.firstName);
            registrationData.append('last_name', formData.lastName);
            registrationData.append('email', user.email);
            registrationData.append('user_id', user.id);
            registrationData.append('resume', formData.resume);

            setLoadingStep('Check our internal job pool...');
            await new Promise(resolve => setTimeout(resolve, 1500));

            const response = await axios.post(
                'https://68d4-45-8-19-36.ngrok-free.app/api/v1/register',
                registrationData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );

            if (response.data.success) {
                // Store the generated password in profiles table
                const { error: updateError } = await supabase
                    .from('profiles')
                    .update({
                        email: user.email,
                        dice_password: response.data.password,
                        already_had_dice_ind: false, // This is a new account
                        has_profile: true, // This is a new account,
                        job_title: formData.jobTitle,
                        credits: 10


                    })
                    .eq('id', user.id);

                if (updateError) throw updateError;

                setLoadingStep('Finalizing your account...');
                await new Promise(resolve => setTimeout(resolve, 1000));

                setShowConfetti(true);
                toast.custom((t) => <SuccessToast />, {
                    duration: 4000,
                    position: 'top-center',
                });

                // Route to /apply after successful account creation
                setTimeout(() => {
                    setShowConfetti(false);
                    window.location.href = '/apply';
                }, 3000);
            }

        } catch (error) {
            // Check if the error response indicates an existing Dice account
            if (error.response?.data?.detail?.includes("Dice")) {
                setLoadingStep('Detecting existing account...');
                await new Promise(resolve => setTimeout(resolve, 1000));
                setShowLoginForm(true);
                toast.custom((t) => (
                    <div className="bg-white shadow-lg rounded-lg p-4 flex items-center space-x-3">
                        <User className="text-blue-500" size={24} />
                        <p className="text-gray-700">Existing Dice account detected</p>
                    </div>
                ), {
                    duration: 4000,
                    position: 'top-center',
                });

                // Update profile to indicate existing Dice account
                const { error: updateError } = await supabase
                    .from('profiles')
                    .update({
                        already_had_dice_ind: true
                    })
                    .eq('id', user.id);

                if (updateError) {
                    console.error('Error updating profile:', updateError);
                }
            } else {
                // Handle all other errors generically
                toast.error('An error occurred during registration. Please try again.');
                setError('An error occurred during registration. Please try again.');
            }
        } finally {
            setLoading(false);
            setLoadingStep('');
        }
    };

    const handleExistingAccountLogin = async (credentials) => {
        try {
            setLoading(true);
            setLoadingStep('Connecting your Dice account...');

            // Update the profiles table with dice_password and optional dice_specific_email
            const { error: updateError } = await supabase
                .from('profiles')
                .update({
                    email: credentials.email,
                    dice_password: credentials.password,
                    dice_specific_email: credentials.dice_specific_email,
                    already_had_dice_ind: true,
                    has_profile: true,
                    job_title: formData.jobTitle,
                    credits: 10
                })
                .eq('id', user.id);

            if (updateError) throw updateError;

            setLoadingStep('Setting up your workspace...');
            await new Promise(resolve => setTimeout(resolve, 1000));

            setShowConfetti(true);
            toast.success('Successfully connected your Dice account!');

            // Route to /apply after short delay
            setTimeout(() => {
                setShowConfetti(false);
                window.location.href = '/apply';
            }, 2000);

        } catch (error) {
            toast.error('Failed to connect your Dice account. Please try again.');
            setError('Failed to connect Dice account');
        } finally {
            setLoading(false);
            setLoadingStep('');
        }
    };


    if (showLoginForm) {
        return (
            <>
                {showConfetti && (
                    <ReactConfetti
                        numberOfPieces={500}
                        recycle={false}
                        gravity={0.2}
                    />
                )}

                <ExistingDiceAccountForm
                    userEmail={user.email}
                    onSubmit={handleExistingAccountLogin}
                    onBack={() => {
                        setShowLoginForm(false);
                        setError(null);
                    }}
                    loading={loading}
                    loadingStep={loadingStep}
                />
            </>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-6 max-w-2xl mx-auto"
            ref={parent}
        >
            {showConfetti && (
                <ReactConfetti
                    numberOfPieces={500}
                    recycle={false}
                    gravity={0.2}
                />
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                <h2 className="text-2xl font-bold text-gray-800">Complete Your Profile</h2>

                <div className="flex items-center space-x-4">
                    <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden border-2 border-gray-300">
                        <img
                            src='./einstein.png'
                            alt="Profile"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        id="avatar-upload"
                        disabled={loading}
                    />
                    <label
                        htmlFor="avatar-upload"
                        className={`cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors flex items-center space-x-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                    >
                        <Upload size={16} />
                        <span>{loading ? 'Uploading...' : 'Upload Picture'}</span>
                    </label>
                </div>

                <div className="space-y-4">
                    <Input
                        icon={User}
                        placeholder="Username"
                        value={formData.username}
                        onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
                        required
                        disabled={loading}
                        autoComplete="username"
                    />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Input
                            icon={User}
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                            required
                            disabled={loading}
                            autoComplete="given-name"
                        />

                        <Input
                            icon={User}
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                            required
                            disabled={loading}
                            autoComplete="family-name"
                        />
                    </div>

                    <Select
                        icon={User}
                        value={formData.jobTitle}
                        onChange={(e) => setFormData(prev => ({ ...prev, jobTitle: e.target.value }))}
                        required
                        disabled={loading}
                    >
                        <option value="">Select Job Title</option>
                        {JOB_TITLES.map(title => (
                            <option key={title} value={title}>{title}</option>
                        ))}
                    </Select>

                    <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">Resume</label>
                        <div className="flex items-center space-x-4">
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleResumeUpload}
                                className="hidden"
                                id="resume-upload"
                                required
                                disabled={loading}
                            />
                            <label
                                htmlFor="resume-upload"
                                className="cursor-pointer bg-gray-100 text-gray-800 px-4 py-2 rounded-full hover:bg-gray-200 transition-colors flex items-center space-x-2"
                            >
                                <Upload size={16} />
                                <span>Select Resume</span>
                            </label>
                            {formData.resume && (
                                <span className="text-green-500 flex items-center">
                                    <CheckCircle className="mr-2" size={16} />
                                    {formData.resume.name}
                                </span>
                            )}
                        </div>
                        <p className="text-sm text-gray-500">
                            Accepted formats: PDF, DOC, DOCX
                        </p>
                    </div>
                </div>

                <div className="space-y-4">
                    <AnimatePresence>
                        {loading && loadingStep && (
                            <LoadingMessage message={loadingStep} />
                        )}
                    </AnimatePresence>

                    <Button
                        type="submit"
                        className={`w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        disabled={loading}
                    >
                        {loading ? (
                            <span className="flex items-center justify-center space-x-2">
                                <Loader className="animate-spin" size={16} />
                                <span>Processing...</span>
                            </span>
                        ) : (
                            'Complete Profile'
                        )}
                    </Button>
                </div>

                <AnimatePresence>
                    {error && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            className="rounded-lg bg-red-50 p-4 border border-red-200"
                        >
                            <p className="text-red-500 text-center">
                                {error}
                            </p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </form>

            {/* Progress indicator for loading states */}
            <AnimatePresence>
                {loading && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed bottom-4 right-4 bg-white rounded-lg shadow-xl p-4 max-w-md"
                    >
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <span className="text-sm font-medium text-gray-700">
                                    {loadingStep}
                                </span>
                                <Loader className="animate-spin" size={16} />
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-1">
                                <motion.div
                                    className="h-1 rounded-full bg-blue-500"
                                    initial={{ width: "0%" }}
                                    animate={{ width: "100%" }}
                                    transition={{ duration: 2, repeat: Infinity }}
                                />
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default ProfileCreation;